import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [

  {
    state: 'home',
    name: 'Home',
    type: 'link',
    icon: 'home'
  }, 
  {
    state: 'calendar',
    name: 'Calendar',
    type: 'link',
    icon: 'date_range'
  },   
  {
    state: 'publish',
    name: 'Publish',
    type: 'link',
    icon: 'volume_up'
  },     
  {
    state: 'maintenance',
    name: 'Maintenance',
    type: 'link',
    icon: 'build'
  }, 
  {
    state: 'transport',
    name: 'Transport',
    type: 'link',
    icon: 'local_taxi'
  },  
  {
    state: 'meal',
    name: 'Meal',
    type: 'link',
    icon: 'restaurant'
  }, 
  {
    state: 'appointment',
    name: 'Appointment',
    type: 'link',
    icon: 'event'
  },   
  {
    state: 'survey',
    name: 'Survey',
    type: 'link',
    icon: 'assessment'
  }, 
  {
    state: 'residents',
    name: 'Residents',
    type: 'link',
    icon: 'hotel'
  }, 
  {
    state: 'groups',
    name: 'Groups',
    type: 'link',
    icon: 'group'
  },
  {
    state: 'notifications',
    name: 'Notifications',
    type: 'link',
    icon: 'mic'
  }


];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
